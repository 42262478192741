// import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
// import Image from "../components/image"
import style from "../assets/global-style"
import { Row, Col } from "react-bootstrap"
import Logo from "../images/footer-yncce-logo.png"
import Tel from "../images/tel.png"
import QRBox from "../components/qrcode-box"

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  min-height: 60px;
  padding: 8px 0;
  width: 100%;
  background: linear-gradient(to right, #1a5377, #117e81 61.11%, #469f48);

  p {
    margin: 0;
  }

  .wrapper {
    margin: 0 auto;
    max-width: 1200px;

    .item {
      color: white;
      width: 28%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .item.left {
      font-size: 12px;
    }
    .item .icp {
      a {
        color:#ffffff
      }
    }
    .item .tel {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px;

      a {
        color: ${style["theme-color"]};
      }

      img {
        margin: 0 5px;
        margin-top: -4px;
        width: 1.2rem;
      }
    }

    .item.logo {
      img {
        width: 20rem;
      }
    }

    @media screen and (max-width: 768px) {
      .item.logo {
        padding: 18px 20px;
      }

      .item {
        padding-left: 20px;
      }

      // * Bootstrap [.d-sm-none] 导致无法居中
      .item.right {
        display: none;
      }
    }

    // * 修复手机屏导航条样式问题
    @media screen and (min-width: 1176px) {
      .item.right {
        text-align: right;
      }

      .item.left {
        text-align: left;
      }
    }

    .item.right {
      font-size: 18px;

      span {
        height: 24px;
        width: 24px;
        position: relative;
      }

      img {
        height: 24px;
        width: 24px;
        margin-left: 14px;
        margin-top: -5px;
      }

      img:hover {
        & + i {
          display: block;
        }
      }
    }
  }
`

const Footer = () => (
  <Container>
    <div className="wrapper">
      <Row>
        <Col className="item left" md={4} xs={12}>
          <p className="tel">
            全国服务热线
            <img src={Tel} alt="tel" />
            <a href="tel:4009999886">4009999886</a>
          </p>
          <p className="icp"><a href="https://beian.miit.gov.cn/?spm=5176.beian-pc.J_9220772140.60.f0d079fetaDsWr#/Integrated/index">ICP备案号：滇ICP备18005498号
</a></p>
          <p>地址：云南省西山区丽苑路城建大厦17楼 ~19楼</p>
          {/* <p>Copyright @ 2019. All rights Reserved</p> */}
        </Col>
        <Col md={4} xs={12} className="item logo">
          <img src={Logo} alt="logo" />
        </Col>
        <Col md={4} xs={12} className="item right">
          <p>
            关注城建物业
            <span>
              <img
                src="http://cdn.jerryshi.com/gtkj/20191231152542.png"
                alt="1"
              />
              <QRBox display="none" img="http://cdn.jerryshi.com/gtkj/20200110115145.jpg" />
            </span>
            <span>
              <img
                src="http://cdn.jerryshi.com/gtkj/20191231152542.png"
                data-qrcode="http://cdn.jerryshi.com/gtkj/20200110115145.jpg"
                alt="2"
              />
              <QRBox display="none" img="http://cdn.jerryshi.com/gtkj/20200110115145.jpg" />
            </span>
            <span>
              <img
                src="http://cdn.jerryshi.com/gtkj/20191231152542.png"
                data-qrcode="http://cdn.jerryshi.com/gtkj/20200110115145.jpg"
                alt="3"
              />
              <QRBox display="none" img="http://cdn.jerryshi.com/gtkj/20200110115145.jpg" />
            </span>
          </p>
        </Col>
      </Row>
    </div>
  </Container>
)

export default Footer
