// import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import style from "../assets/global-style"
import Logo from "../images/header-yncce-logo.png"
import { Link } from "gatsby"

import { Nav, Navbar, NavDropdown } from "react-bootstrap"

const Container = styled.header`
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .navbar-light .navbar-nav .nav-link {
    color: white;

    padding-right: 1.2rem;
    padding-left: 1.2rem;
  }

  .dropdown-toggle::after {
    margin: 0;
    border: none;
  }

  .navbar-toggler {
    border-color: ${style["theme-color"]};
  }

  // * 修复手机屏导航条样式问题
  @media screen and (min-width: 1176px) {
    .dropdown-menu.show {
      margin-top: 1.1rem;
      margin-left: 1rem;
      display: flex;
      flex-direction: row;
    }
  }

  .dropdown-menu.show {
    background: rgba(255, 255, 255, 0.5);

    .dropdown-item {
      padding: 0.38rem;
      font-size: 14px;
    }
  }

  .dropdown-item {
    a { color: #555;}
  }

  .nav-link {
    a { color: white;}
  }

  a.nav-link:hover {
    background: ${style["theme-color"]};
  }
`

const data = [
  {
    title: "首页",
    url: "/",
  },
  {
    title: "关于我们",
    url: null,
    children: [
      {
        title: "经营理念",
        url: "/about-us/idea",
      },
      {
        title: "组织架构",
        url: "/about-us/organization",
      },
      {
        title: "发展历程",
        url: "/about-us/history",
      },
    ],
  },
  {
    title: "业务领域",
    url: null,
    children: [
      {
        title: "物业服务",
        url: "/business/wuye",
      },
      {
        title: "智能科技",
        url: "/business/getai",
      },
      {
        title: "教育培训",
        url: "/business/brjy",
      },
      {
        title: "房产经纪",
        url: "/business/fcjj",
      },
      {
        title: "家政服务",
        url: "/business/jzfw",
      },
      {
        title: "酒店运营",
        url: "/business/jdyy",
      },
      {
        title: "装饰装修",
        url: "/business/zszx",
      },
      {
        title: "绿化工程",
        url: "/business/lhgc",
      },
    ],
  },
  {
    title: "资讯中心",
    url: "/news",
  },
  {
    title: "加入我们",
    url: "/join-us",
  },
  {
    title: "联系我们",
    url: "/contact-us",
  },
]

const renderNavs = data =>
  data.map(d => {
    if (d && d.children) {
      return (
        <NavDropdown key={d.title} title={d.title} id="basic-nav-dropdown">
          {d.children.map(c => (
            <NavDropdown.Item as="span"  key={c.title}>
              <Link to={c.url}>{c.title}</Link>
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      )
    } else {
      return (
        <Nav.Link as="span" key={d.title}>
          <Link to={d.url}>{d.title}</Link>
        </Nav.Link>
      )
    }
  })

const Header = () => {
  return (
    <Container>
      <Navbar expand="lg" style={{ margin: "0 auto", maxWidth: 1200 }}>
        <Navbar.Brand href="/">
          <img style={{ maxWidth: 320 }} src={Logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {renderNavs(data)}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  )
}

export default Header
