import React from "react"
import styled from "styled-components"

const Box = styled.i`
  background: url(${props => props.img}) center no-repeat;
  background-size: 80%;
  padding: 6px;
  position: absolute;
  top: -110px;
  left: -18px;
  background-color: #eee;
  padding: 30px;
  /* 无法了，先这样 */
  display: ${props => props.display ? props.display : 'block'};

  span.tail-bottom {
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    position: absolute;
    border-width: 14px;
    border-style: solid dashed dashed;
    border-color: #eee transparent transparent;
    left: 0px;
    bottom: -48px;
  }
`

const QRbox = ({ img, display }) => <Box display={display} img={img}><span className="tail-bottom"></span></Box>

export default QRbox
